import {Component} from '@angular/core';
import {BlockManService} from '../block-man.service';
import {IBlock, IExample, ISetting} from '@root/common/blocks';
import {MessageBoxService} from '@upm/message-box.service';

@Component({
	selector: 'upm-bb-examples',
	templateUrl: './bb-examples.component.html',
	styleUrls: ['./bb-examples.component.less']
})
export class BbExamplesComponent {
	block: IBlock = null;
	activeExample: IExample = null;

	constructor(private readonly blockman: BlockManService, private readonly messageBox: MessageBoxService) {
		this.blockman.observe().subscribe((block: IBlock) => {
			this.block = block;
			this.update();
		});
	}

	private update() {
		if (this.activeExample == null) {
			if (this.block.examples.length) {
				this.activeExample = this.block.examples[0];
			}
		}
	}

	addNewExampleSet() {
		this.blockman.addExampleSet();
	}

	async deleteExampleSet(example: IExample) {
		if (await this.messageBox.confirmDeleteFor(example.name)) {
			this.blockman.deleteExampleSet(example);
			if (this.activeExample === example) {
				this.activeExample = null;
			}
		}
	}

	copyExampleSet(example: IExample) {
		const ex = JSON.parse(JSON.stringify(example)) as IExample;
		ex.name += " Copy";
		this.block.examples.push(ex);
	}

	bindExampleSettingValue(example: IExample, setting: ISetting) {
		this.blockman.bindSettingValue(example, setting);
	}

	activateExample(example: IExample) {
		this.activeExample = example;
	}


}
