import {Component, DoCheck, Input} from '@angular/core';
import {IBlock, IExample, IExampleValue, InputType} from '@root/common/blocks';
import {BlockManService, IValidationResult} from '../block-man.service';
import {JsonEditorComponent, JsonEditorOptions} from "ang-jsoneditor";

@Component({
	selector: 'upm-bb-example-editor',
	templateUrl: './bb-example-editor.component.html',
	styleUrls: ['./bb-example-editor.component.less']
})
export class BbExampleEditorComponent implements DoCheck {

	@Input() example: IExample;
	block: IBlock = null;

	private validationResult: IValidationResult = null;

	private jsonEditorOptions = new JsonEditorOptions();

	constructor(private readonly blockman: BlockManService) {
		this.blockman.observe().subscribe((block: IBlock) => {
			this.block = block;
			this.update();
		});

		this.jsonEditorOptions.mainMenuBar = false;
		this.jsonEditorOptions.mode = "text";
		this.jsonEditorOptions.onError = (err) => {
			console.log("ERROR", err)
		}
	}

	ngOnInit() {
		this.validationResult = this.blockman.validateExample(this.example);
	}

	update() {

	}

	get isValid() {
		return this.validationResult.valid;
	}

	get isMissingSettings() {
		return this.blockman.isExampleMissingSettings(this.example);
	}

	get isMissingInputs() {
		return this.blockman.isExampleMissingInputs(this.example);
	}

	ngDoCheck(): void {
		this.validationResult = this.blockman.validateExample(this.example);
	}

	addMissingExamples() {
		this.blockman.addMissingValues(this.example);
	}


	getNameFromReference(refernceId: any) {
		for (let setting of this.block.settings) {
			if (setting.id === refernceId) {
				return setting;
			}
		}

		for (let input of this.block.variables) {
			if (input.id === refernceId) {
				return input;
			}
		}
		return {"name": "???"};
	}

	useBigInput(refernceId: any) {

		for (let input of this.block.variables) {
			if (input.id === refernceId) {
				if (input.type === InputType.Object)
					return true;
			}
		}
		return false;
	}

	onChange(component: JsonEditorComponent) {
		console.log("ON CHANGE", component)
	}

	getDataFor(value: IExampleValue, component: JsonEditorComponent) {
		try {
			component.writeValue(JSON.parse(value.value));
		} catch (e) {

		}
	}
}
