import {Component, OnInit} from '@angular/core';
import {BlockHostService, IBlockMetaDescriptor} from '@bb/block-host.service';
import {MessageBoxService} from '@upm/message-box.service';
import {BlockManService} from '@bb/block-man.service';
import {SessionService} from "@upm/session.service";
import {IBlock, makeDefaultBlock} from "@common/blocks";
import {ConfirmDeleteDialogComponent} from "@upm/sections/common/confirm-delete-dialog/confirm-delete-dialog.component";
import {MatDialog} from "@angular/material";
import {BbTransferDialogComponent} from "@bb/bb-transfer-dialog/bb-transfer-dialog.component";

@Component({
	selector: 'upm-bb-block-picker',
	templateUrl: './bb-block-picker.component.html',
	styleUrls: ['./bb-block-picker.component.less']
})
export class BbBlockPickerComponent implements OnInit {

	loading: boolean = true;
	blocks: IBlockMetaDescriptor[] = [];

	newBlockName: string = '';

	constructor(
		private readonly host: BlockHostService,
		private readonly blockman: BlockManService,
		private readonly mbox: MessageBoxService,
		private readonly session: SessionService,
		private readonly dialog: MatDialog
	) {

	}

	async update() {
		this.loading = true;
		this.blocks = await this.host.getBlockList();

		this.blocks.sort((a, b) => {

			if (a.name === b.name) {
				return 0;
			}

			if (a.name < b.name) {
				return -1;
			}

			return 1;
		});

		for (let block of this.blocks) {
			if (block.id === 5740039110656000) {
				console.log("LBOC", block);
			}
		}

		this.loading = false;
		this.newBlockName = '';
	}

	async ngOnInit() {
		await this.update();
	}

	async signOut() {
		await this.session.signOut();
	}

	async open(block: IBlockMetaDescriptor) {
		this.loading = true;
		const config = await this.host.getBlockConfiguration(block.id);
		if (config === null) {
			this.blockman.createNew(block);
		} else {
			this.blockman.open(config);
		}
		this.loading = false;
	}

	get canCreateNewBlock() {
		return this.newBlockName && this.newBlockName.length > 0;
	}

	async createNewBlock() {
		this.loading = true;
		await this.host.createNewBlock(this.newBlockName);
		await this.update();
	}

	async deleteBlock(block: IBlockMetaDescriptor) {
		if (await this.mbox.confirmDeleteFor(block.name)) {
			this.loading = true;
			await this.host.updateBlock(block.id, block.name, true);
			await this.update();
		}
	}

	private async getSourceBlock(block: IBlockMetaDescriptor) {
		let source = await this.host.getBlockConfiguration(block.id);
		if (source === null) {
			const sourceBlock = makeDefaultBlock(block.id, block.name);
			await this.host.setBlockConfiguration(sourceBlock);
			source = await this.host.getBlockConfiguration(block.id);
		}

		const sourceBlock = JSON.parse(source.block) as IBlock;
		sourceBlock.backend = source.backend;

		return sourceBlock
	}

	async copyBlock(block: IBlockMetaDescriptor) {
		this.loading = true;

		const sourceBlock = await this.getSourceBlock(block);

		const destBlockName = block.name + " Copy";
		const destBlockId = await this.host.createNewBlock(destBlockName);
		const destBlock = makeDefaultBlock(destBlockId, destBlockName);

		for (let key in sourceBlock) {
			if (key !== "id" && key !== "name") {
				destBlock[key] = sourceBlock[key];
			}
		}

		await this.host.setBlockConfiguration(destBlock);
		await this.update();
		this.loading = false;
	}

	async renameBlock(block: IBlockMetaDescriptor) {
		const name = prompt("Rename block", block.name);
		if (name != block.name) {
			this.loading = true;
			const sourceBlock = await this.getSourceBlock(block);
			sourceBlock.name = name;
			await this.host.renameBlock(block.id, name);
			await this.host.setBlockConfiguration(sourceBlock);
			await this.update();
			this.loading = false;
		}
	}

	async transferBlock(block: IBlockMetaDescriptor) {

		const dialogRef = this.dialog.open(BbTransferDialogComponent, {
			width: '450px',
			panelClass: 'default-dialog-container',
			data: {block: block}
		});

		const transferred = await dialogRef.afterClosed().toPromise();
		if (transferred) {
			await this.update()
		}
	}
}
