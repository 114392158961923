import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
	selector: 'upm-error-dialog',
	templateUrl: './error-dialog.component.html',
	styleUrls: ['./error-dialog.component.less']
})
export class ErrorDialogComponent {

	error: string = '';

	constructor(@Inject(MAT_DIALOG_DATA) public data: { error: string }) {
		this.error = data.error;
	}

}
