import {ChangeDetectorRef, Component} from '@angular/core';
import {SessionService} from '@upm/session.service';
import {MessageBoxService} from '@upm/message-box.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent {
	constructor(
		readonly session: SessionService,
		private readonly mbox: MessageBoxService,
		private readonly chdet: ChangeDetectorRef,
	) {
		mbox.changes.subscribe(() => {
			chdet.detectChanges();
		});
	}

	get globalError(): string | null {
		return this.session.globalErrorMessage;
	}

	get isFrozen() {
		return this.mbox.isFrozen;
	}

	get frostText(): string {
		return this.mbox.topFrozenScreen.text;
	}

	get isSignedIn(): boolean {
		return this.session.isSignedIn;
	}

}
