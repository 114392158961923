import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {
	MatButtonModule, MatCheckboxModule, MatRadioModule,
	MatDialogModule, MatInputModule, MatStepperModule,
	MatSelectModule, MatIconModule, MatTooltipModule,
	MatSlideToggleModule, MatSliderModule,
	MatProgressSpinnerModule,
	MatAutocompleteModule,
	MatTabsModule,
	MatMenuModule,
	MatProgressBarModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatTreeModule, MatCardModule
} from '@angular/material';

import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {MonacoEditorModule} from 'ngx-monaco-editor';
import {QuillModule} from 'ngx-quill';

import {SafePipe} from './common/safe.pipe';

import {BbHomeComponent} from '@bb/bb-home/bb-home.component';
import {BbDesignViewComponent} from '@bb/bb-design-view/bb-design-view.component';
import {BbStructureEditorComponent} from '@bb/bb-structure-editor/bb-structure-editor.component';
import {BbStyleEditorComponent} from '@bb/bb-style-editor/bb-style-editor.component';
import {BbPreviewComponent} from '@bb/bb-preview/bb-preview.component';
import {LessModelDirective} from '@bb/less-model.directive';
import {BbBehaviorEditorsComponent} from '@bb/bb-behavior-editors/bb-behavior-editors.component';
import {BbCodeEditorComponent} from '@bb/bb-code-editor/bb-code-editor.component';
import {BbNestEditorComponent} from '@bb/bb-nest-editor/bb-nest-editor.component';
import {BbNestFieldEditorComponent} from '@bb/bb-nest-editor/bb-nest-field-editor/bb-nest-field-editor.component';
import {BbNestNodeEditorComponent} from '@bb/bb-nest-editor/bb-nest-node-editor/bb-nest-node-editor.component';
import {BbLayoutComponent} from '@bb/bb-preview/bb-layout/bb-layout.component';
import {BbVariableEditorComponent} from '@bb/bb-variable-editor/bb-variable-editor.component';
import {BbSettingsEditorComponent} from '@bb/bb-settings-editor/bb-settings-editor.component';
import {ConfirmDeleteDialogComponent} from './sections/common/confirm-delete-dialog/confirm-delete-dialog.component';
import {BbExamplesComponent} from '@bb/bb-examples/bb-examples.component';
import {BbExampleEditorComponent} from '@bb/bb-example-editor/bb-example-editor.component';
import {BbRenderComponent} from '@bb/bb-preview/bb-render/bb-render.component';
import {BbBlockPickerComponent} from '@bb/bb-block-picker/bb-block-picker.component';
import {BbLauncherComponent} from '@bb/bb-launcher/bb-launcher.component';
import {ConfirmDiscardDialogComponent} from '@upm/sections/common/confirm-discard-dialog/confirm-discard-dialog.component';
import {BbBackendEditorComponent} from '@bb/bb-backend-editor/bb-backend-editor.component';
import {ErrorDialogComponent} from '@upm/sections/common/error-dialog/error-dialog.component';
import {ConfirmSaveDialogComponent} from '@upm/sections/common/confirm-save-dialog/confirm-save-dialog.component';
import {BbIsolationViewComponent} from '@bb/bb-isolation-view/bb-isolation-view.component';
import {BbNestNodeAttrsEditorComponent} from '@bb/bb-nest-editor/bb-nest-node-attrs-editor/bb-nest-node-attrs-editor.component';
import {NgJsonEditorModule} from "ang-jsoneditor";
import {SigninComponent} from './signin/signin.component';
import {SessionService} from "@upm/session.service";
import {BbDebuggerComponent} from '@bb/bb-debugger/bb-debugger.component';
import { BbTransferDialogComponent } from './sections/block-builder/bb-transfer-dialog/bb-transfer-dialog.component';

const routes: Routes = [
	{path: 'isolate-block', component: BbIsolationViewComponent, canActivate: [SessionService]},
	{path: 'signin', component: SigninComponent},
	{path: 'debugger', component: BbDebuggerComponent},
	{path: '**', component: BbHomeComponent, canActivate: [SessionService]}
];

@NgModule({

	entryComponents: [
		ConfirmDeleteDialogComponent,
		ConfirmDiscardDialogComponent,
		ConfirmSaveDialogComponent,
		BbTransferDialogComponent,
		ErrorDialogComponent
	],

	declarations: [
		AppComponent,
		LessModelDirective,
		ConfirmDeleteDialogComponent,
		ConfirmDiscardDialogComponent,
		ConfirmSaveDialogComponent,
		ErrorDialogComponent,
		SafePipe,

		BbHomeComponent,
		BbDesignViewComponent,
		BbStructureEditorComponent,
		BbStyleEditorComponent,
		BbPreviewComponent,
		BbBehaviorEditorsComponent,
		BbCodeEditorComponent,
		BbNestEditorComponent,
		BbNestFieldEditorComponent,
		BbNestNodeEditorComponent,
		BbLayoutComponent,
		BbVariableEditorComponent,
		BbSettingsEditorComponent,
		BbExamplesComponent,
		BbExampleEditorComponent,
		BbRenderComponent,
		BbBlockPickerComponent,
		BbLauncherComponent,
		BbBackendEditorComponent,
		BbIsolationViewComponent,
		BbNestNodeAttrsEditorComponent,
		SigninComponent,
		BbDebuggerComponent,
		BbTransferDialogComponent,

	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,

		MatButtonModule,
		MatCheckboxModule,
		MatButtonModule,
		MatRadioModule,
		MatCheckboxModule,
		MatDialogModule,
		MatInputModule,
		MatStepperModule,
		MatSelectModule,
		MatIconModule,
		MatTooltipModule,
		MatTabsModule,
		MatSlideToggleModule,
		MatSliderModule,
		MatMenuModule,
		MatTreeModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatAutocompleteModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCardModule,
		NgJsonEditorModule,

		RouterModule.forRoot(routes, {enableTracing: false}),

		QuillModule.forRoot({
			theme: 'bubble',
			modules: {
				syntax: false,

				toolbar: [
					['bold', 'italic', 'underline'],        // toggled buttons
					[{'header': 1}, {'header': 2}],
					[{'size': ['small', false, 'large']}],
					//['blockquote', 'code-block'],
					[{'align': []}],
					//[{'list': 'ordered'}, {'list': 'bullet'}],
					//[{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
					[{'color': []}, {'background': []}],          // dropdown with defaults from theme
					//[{'font': ["Roboto"]}],
					//['clean'],                                         // remove formatting button
					//['link', 'image', 'video']                         // link and image, video
				]
			}
		}),

		HttpClientModule,

		/*MonacoEditorModule.forRoot({
			// Angular CLI currently does not handle assets with hashes. We manage it by manually adding
			// version numbers to force library updates:
			baseUrl: 'lib',
			defaultOptions: {},
		})*/

		MonacoEditorModule.forRoot()
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule {
}
