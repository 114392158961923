import {Component, NgZone} from '@angular/core';
import {IBlock, IExample} from '@root/common/blocks';

import * as less from 'less';
import {debounce} from '@root/util/debounce';
import {BlockManService} from '../block-man.service';
import {BlockBuilderComponentBase} from '@bb/base/ComponentBase';
import {BlockHostService} from '@bb/block-host.service';

@Component({
	selector: 'upm-bb-preview',
	templateUrl: './bb-preview.component.html',
	styleUrls: ['./bb-preview.component.less'],
})
export class BbPreviewComponent extends BlockBuilderComponentBase {

	block: IBlock = null;

	loading: boolean = false;
	activeExample: IExample = null;

	constructor(private zone: NgZone,
				private readonly host: BlockHostService,
				blockman: BlockManService) {
		super(blockman);
	}

	async update() {

		this.blockman.clearGlobalError('VUE');
		this.blockman.clearGlobalError('LESS');
		this.blockman.clearGlobalError('JAVASCRIPT');

		if (this.activeExample == null) {
			if (this.block.examples.length > 0) {
				this.blockman.setActiveExample(this.activeExample);
				this.activeExample = this.block.examples[0];
			}
		}

		await this.zone.run(async () => {
			this.blockman.setActiveExample(this.activeExample);
		});
	}

	get valid() {
		return !this.error;
	}

	get ready() {
		return this.host.glue != null;
	}

	get error() {
		return this.blockman.globalError;
	}
}
