import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
	selector: 'upm-confirm-delete-dialog',
	templateUrl: './confirm-delete-dialog.component.html',
	styleUrls: ['./confirm-delete-dialog.component.less']
})
export class ConfirmDeleteDialogComponent {

	name: string = '';

	constructor(@Inject(MAT_DIALOG_DATA) public data: { name: string }) {
		this.name = data.name;
	}

}
