function checkNodeClassName(node: Element, name: string) {
	return node.classList.contains(name);
}

export function findChildWithClassName(node: Element, name: string) {

	if (checkNodeClassName(node, name)) {
		return node;
	}

	for (let i = 0; i < node.children.length; ++i) {
		const child = node.children[i];
		const result = findChildWithClassName(child, name);
		if (result != null) {
			return result;
		}
	}

	return null;
}
