import {Component} from '@angular/core';
import {BlockBuilderComponentBase} from '@bb/base/ComponentBase';
import {BlockManService} from '@bb/block-man.service';
import {BlockHostService} from '@bb/block-host.service';
import {MessageBoxService} from '@upm/message-box.service';
import {Router} from '@angular/router';
import {SessionService} from "@upm/session.service";

@Component({
	selector: 'upm-bb-launcher',
	templateUrl: './bb-launcher.component.html',
	styleUrls: ['./bb-launcher.component.less']
})
export class BbLauncherComponent extends BlockBuilderComponentBase {


	constructor(blockman: BlockManService,
				private router: Router,
				private session: SessionService,
				private readonly mbox: MessageBoxService,
				private readonly host: BlockHostService) {
		super(blockman);
	}

	get unsaved() {
		return this.blockman.unsaved;
	}

	async save() {
		await this.host.save();
	}

	async unloadBlock() {
		if (this.unsaved) {
			if (await this.mbox.confirmDiscardFor(this.block.name)) {
				await this.blockman.set(null);
			}
		} else {
			await this.blockman.set(null);
		}
	}

	refresh() {
		this.blockman.signalReload();
	}

	async launchExternally() {

		if (this.unsaved) {
			if (await this.mbox.confirmSaveFor(this.block.name)) {
				await this.host.save();
			} else {
				return;
			}
		}

		const params = {'blockId': this.blockman.block.id};
		const ae = this.blockman.activeExample;
		if (ae) {
			params['exampleIdx'] = this.block.examples.indexOf(ae);
		}
		await this.router.navigate(['isolate-block', params]);
	}

	get hasAccessToBuildTools(): boolean {
		return this.session.isDeveloper;
	}

	async openDebugger() {
		await this.router.navigate(["debugger"]);
	}
}
