import {Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output} from '@angular/core';

@Directive({
	selector: '[upmLessModel]',
})
export class LessModelDirective implements OnChanges {

	@Input('upmLessModel') model: string;
	@Output('upmLessModelChange') update = new EventEmitter();

	private lastValue: string;

	constructor(private elRef: ElementRef) {
	}

	ngOnChanges(changes) {
		if (this.lastValue != this.model) {
			this.lastValue = this.model;
			this.refreshView();
		}
	}

	@HostListener('blur')
	@HostListener('keyup') onContentUpdated() {
		const value = this.elRef.nativeElement.innerText;
		this.lastValue = value;
		this.update.emit(value);
	}

	private refreshView() {
		this.elRef.nativeElement.innerText = this.model;
	}
}
