import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from '@upm/app.module';
import {environment} from './environments/environment';

if (environment.production) {
	enableProdMode();
}
document.addEventListener("keydown", function (e) { //Disable Control-S, Command-S
	if (e.key == "s" && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
		e.preventDefault();
	}
}, false);

platformBrowserDynamic().bootstrapModule(AppModule)
	.catch(err => console.error(err));
