import {EventEmitter, Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';

import {IBlockConfig, IBlockMetaDescriptor} from '@bb/block-host.service';
import {IBlock, IBlockNode, IExample, ISetting, makeDefaultBlock, makeDefaultNode, traverse} from "@common/blocks";
import {makeUUID} from "@common/dom";

const BLOCKSTOREKEY = 'OpenBlockCache';
const BLOCKSTORESTATEKEY = 'OpenBlockCacheState';


export interface IValidationResult {
	valid: boolean;
	error?: string;
}

export interface INodeMap {
	[id: string]: IBlockNode
}

export interface IBlockStoreCacheState {
	blockId: number;
	state: 'prestine' | 'modified'
}


@Injectable({
	providedIn: 'root'
})
export class BlockManService {

	public focusNode: IBlockNode = null;

	public readonly reloads = new EventEmitter<void>();

	block: IBlock = null;
	state: IBlockStoreCacheState = null;
	backendSyntaxValid: boolean = true;

	private readonly stream = new ReplaySubject<IBlock>(1);

	private globalErrors: { [key: string]: string } = {};
	private globalErrorStable: { 'key': string, 'error': string } = null;
	private globalErrorStableSet: boolean = false;

	private _activeExample: IExample = null;
	private _block_json_old: string = null;
	private _state_json_old: string = null;
	private _cutNode: IBlockNode = null;


	constructor() {
		this.block = this.loadLocalVersion();
		setInterval(() => {
			this.autosaveChange();
		}, 1000);
	}

	update() {
		this.stream.next(this.block);
	}

	set(block: IBlock | null, state: IBlockStoreCacheState | null = null) {

		this.block = block;
		if (block) {

			this.migrate();

			const modify = !state;

			state = state || {
				blockId: block.id,
				state: 'prestine'
			};

			this.state = state;
			this._block_json_old = null;
			this.autosaveChange(modify);

		} else {
			localStorage.removeItem(BLOCKSTOREKEY);
			localStorage.removeItem(BLOCKSTORESTATEKEY);
		}
	}

	private migrate() {

		// Add Inputs field to all examples
		for (let example of this.block.examples) {
			if (!example.inputs) {
				example.inputs = [];
			}
		}
	}

	observe() {
		return this.stream.asObservable();
	}

	addChildBlock(node: IBlockNode) {
		const child = makeDefaultNode();
		node.children.push(child);
	}

	promote(up: boolean, fully: boolean, node: IBlockNode) {
		const parent = this.findParent(this.block.root, node);
		if (!parent) {
			return;
		}

		const dir = up ? 1 : -1;

		const children = parent.children;
		const lastIdx = children.length - 1;

		const oidx = children.indexOf(node);
		let nidx = Math.min(lastIdx, Math.max(0, oidx + dir));

		if (fully) {
			if (!up) {
				nidx = 0;
			} else {
				nidx = lastIdx;
			}
		}

		children.splice(nidx, 0, children.splice(oidx, 1)[0]);
	}

	findParent(root: IBlockNode, node: IBlockNode): IBlockNode | null {

		for (let c of root.children) {
			if (c.id === node.id) {
				return root;
			}
		}

		for (let c of root.children) {
			const parent = this.findParent(c, node);
			if (parent != null) {
				return parent;
			}
		}

		return null;
	}

	removeBlock(node: IBlockNode, replace: IBlockNode[] | null = null) {

		if (node.isRootNode) {
			return;
		}

		if (node === this._cutNode) {
			this._cutNode = null;
		}

		const parent = this.findParent(this.block.root, node);
		if (parent != null) {
			const idx = parent.children.indexOf(node);
			if (replace) {
				parent.children.splice(idx, 1, ...replace);
			} else {
				parent.children.splice(idx, 1);
			}
		}
	}

	isAlive(node: IBlockNode) {
		if (node === this.block.root) {
			return true;
		}

		if (this.findParent(this.block.root, node)) {
			return true;
		}

		return false;
	}

	wrapBlock(node: IBlockNode) {
		const wrapper = makeDefaultNode();
		this.removeBlock(node, [wrapper]);
		wrapper.children = [node];
	}

	unwrapBlock(node: IBlockNode) {
		this.removeBlock(node, node.children);
	}

	set cutNode(node: IBlockNode) {
		this._cutNode = node;
	}

	get cutNode() {
		return this._cutNode;
	}

	canPasteNode(target: IBlockNode) {
		if (!this._cutNode)
			return false;

		const node = this._cutNode;

		if (node === target)
			return false;

		return this.findParent(node, target) === null;
	}

	pasteNode(target: IBlockNode) {

		if (this.canPasteNode(target)) {
			const cut = this._cutNode;
			this.removeBlock(cut);
			target.children.push(cut);
		}
	}

	private rewriteIds(node: IBlockNode) {
		node.id = makeUUID();
		for (let child of node.children)
			this.rewriteIds(child);
	}

	duplicateNode(node: IBlockNode) {

		if (node.isRootNode)
			return;

		const copy = JSON.parse(JSON.stringify(node)) as IBlockNode;
		this.rewriteIds(copy);

		const parent = this.findParent(this.block.root, node);
		parent.children.push(copy);
	}

	private checkForRequiredSettings() {
		for (let setting of this.block.settings) {
			if (setting.required) {
				return true;
			}
		}
		return false;
	}

	private invalid(reason: string): IValidationResult {
		return {valid: false, error: reason};
	}

	private valid(): IValidationResult {
		return {valid: true};
	}

	private traverse(node: IBlockNode, collection: INodeMap = {}) {
		collection[node.id] = node;
		for (let child of node.children) {
			this.traverse(child, collection);
		}
		return collection;
	}

	removeSetting(setting: ISetting) {
		const idx = this.block.settings.indexOf(setting);
		if (idx !== -1) {
			this.block.settings.splice(idx, 1);
			for (let example of this.block.examples) {
				example.values = example.values
					.filter((v) => v.referenceId !== setting.id);
			}
		}
	}

	validateExamples(): IValidationResult {

		if (this.block.examples.length === 0) {
			if (this.checkForRequiredSettings()) {
				return this.invalid('There are required settings without examples');
			}

			if (this.block.variables.length > 0) {
				return this.invalid('There are required inputs without examples');
			}
		}

		for (let example of this.block.examples) {
			const result = this.validateExample(example);
			if (!result.valid) {
				return result;
			}
		}

		return this.valid();
	}

	isExampleMissingSettings(example: IExample) {
		for (let setting of this.block.settings) {
			if (setting.required) {
				if (example.values.filter((v) => v.referenceId === setting.id).length === 0) {
					return true;
				}
			}
		}
		return false;
	}

	isExampleMissingInputs(example: IExample) {
		for (let input of this.block.variables) {
			if (example.inputs.filter((v) => v.referenceId === input.id).length === 0) {
				return true;
			}
		}

		return false;
	}

	validateExample(example: IExample): IValidationResult {

		if (this.isExampleMissingSettings(example)) {
			return this.invalid('Example "' + example.name + '" has some missing settings');
		}

		if (this.isExampleMissingInputs(example)) {
			return this.invalid('Example "' + example.name + '" has some missing inputs');
		}

		return this.valid();
	}

	addExampleSet() {
		this.block.examples.push({
			name: 'Example Set ' + (this.block.examples.length + 1),
			description: '',
			values: [],
			inputs: [],
		});
	}

	deleteExampleSet(example: IExample) {
		const idx = this.block.examples.indexOf(example);
		if (idx !== -1) {
			this.block.examples.splice(idx, 1);
		}
	}

	bindSettingValue(example: IExample, setting: ISetting) {
		example.values.push({referenceId: setting.id, value: ''});
	}

	addMissingValues(example: IExample) {
		for (let setting of this.block.settings) {
			if (setting.required) {
				if (example.values.filter((v) => v.referenceId === setting.id).length === 0) {
					example.values.push({
						referenceId: setting.id,
						value: ''
					});

				}
			}
		}

		for (let setting of this.block.variables) {
			if (example.inputs.filter((v) => v.referenceId === setting.id).length === 0) {
				example.inputs.push({
					referenceId: setting.id,
					value: ''
				});
			}
		}
	}

	setGlobalError(key: string, error: string) {
		this.globalErrorStableSet = false;
		this.globalErrorStable = null;
		this.globalErrors[key] = error;
	}

	clearGlobalError(key: string) {
		this.globalErrorStableSet = false;
		this.globalErrorStable = null;
		delete this.globalErrors[key];
	}

	clearGlobalErrors() {
		this.globalErrorStableSet = false;
		this.globalErrorStable = null;
		this.globalErrors = {};
	}

	setActiveExample(example: IExample) {
		if (this._activeExample != example) {
			this._activeExample = example;
			this.update();
		}
	}

	open(config: IBlockConfig) {
		const block = JSON.parse(config.block) as IBlock;
		block.backend = config.backend;
		this.set(block);
	}

	createNew(block: IBlockMetaDescriptor) {
		this.set(makeDefaultBlock(block.id, block.name));
	}

	loadLocalVersion() {

		let state: IBlockStoreCacheState | null = null;
		const stateJson = localStorage.getItem(BLOCKSTORESTATEKEY);
		if (stateJson) {
			state = JSON.parse(stateJson);
		}

		const json = localStorage.getItem(BLOCKSTOREKEY);

		if (json) {

			const block = JSON.parse(json);

			state = state || {
				blockId: block.id,
				state: 'modified'
			};

			this.set(block, state);

		} else {
			this.block = null;
		}

		return this.block;
	}

	saveState() {
		localStorage.setItem(BLOCKSTORESTATEKEY, JSON.stringify(this.state));
	}

	autosaveChange(modify: boolean = true) {

		let block = this.block;
		if (block) {
			const json = JSON.stringify(block);

			if (json !== this._block_json_old) {
				console.log("Update detected");

				let stateJson = JSON.stringify(this.state);

				if (this.state) {

					if (this.state.blockId != block.id) {
						this.state = {
							blockId: block.id,
							state: 'prestine'
						};
					} else if (modify) {
						this.state.state = 'modified';
					}
				} else {
					this.state = {
						blockId: block.id,
						state: 'prestine'
					};
				}

				localStorage.setItem(BLOCKSTOREKEY, json);
				this.saveState();

				this._block_json_old = json;
				this._state_json_old = stateJson;

				this.update();
			}
		}
	}

	signalReload() {
		this.clearGlobalErrors();
		this.reloads.emit();
	}

	get nodeMap(): INodeMap {
		return traverse(this.block.root);
	}

	get globalError(): { 'key': string, 'error': string } | null {

		if (this.globalErrorStableSet) {
			return this.globalErrorStable;
		}

		const keys = Object.keys(this.globalErrors);
		if (keys.length === 0) {
			this.globalErrorStable = null;
			this.globalErrorStableSet = true;
			return null;
		}

		const key = keys[0];

		this.globalErrorStable = {'key': key, 'error': this.globalErrors[keys[0]]};
		this.globalErrorStableSet = true;

		return this.globalErrorStable;
	}

	get activeExample() {
		return this._activeExample;
	}

	get unsaved() {
		return this.state && this.state.state === 'modified';
	}


}
