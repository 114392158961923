import {Component} from '@angular/core';
import {BlockManService} from '../block-man.service';
import {IBlock, Input, InputType} from '@root/common/blocks';
import {makeReandomIdentifier} from '@root/common/dom';

@Component({
	selector: 'upm-bb-variable-editor',
	templateUrl: './bb-variable-editor.component.html',
	styleUrls: ['./bb-variable-editor.component.less']
})
export class BbVariableEditorComponent {

	block: IBlock | null = null;
	types = [InputType.Object, InputType.Number, InputType.String];

	constructor(private readonly blockman: BlockManService) {
		this.blockman.observe().subscribe((block: IBlock) => {
			this.block = block;
			this.update();
		});
	}

	update() {

	}

	addNewInput() {
		if (this.block) {
			this.block.variables.push({
				id: makeReandomIdentifier(),
				name: 'input_' + (this.block.variables.length + 1).toString(),
				description: 'Add variable description here',
				type: InputType.Object
			});
		}
	}

	deleteInput(variable: Input) {
		if (this.block) {
			const idx = this.block.variables.indexOf(variable);
			if (idx !== -1) {
				this.block.variables.splice(idx, 1);
			}
		}
	}

	inputTypeName(ct: InputType.Object | InputType.Number | InputType.String) {
		switch (ct) {
			case InputType.Object:
				return "Json Object or Array";
			case InputType.Number:
				return "Number";
			case InputType.String:
				return "String";
		}
	}
}
