import {Component} from '@angular/core';
import {BlockManService} from '../block-man.service';
import {BlockBuilderComponentBase} from '@bb/base/ComponentBase';


type Mode = "css" | "client" | "server"

@Component({
	selector: 'upm-bb-behavior-editors',
	templateUrl: './bb-behavior-editors.component.html',
	styleUrls: ['./bb-behavior-editors.component.less']
})
export class BbBehaviorEditorsComponent extends BlockBuilderComponentBase {

	mode: Mode = "css";

	constructor(readonly blockman: BlockManService) {
		super(blockman);
	}

	get invalidBackendSyntax() {
		return !this.blockman.backendSyntaxValid;
	}

	setMode(mode: Mode) {
		this.mode = mode;
	}
}
