import {Component, Input, OnInit} from '@angular/core';
import {IBlock} from '@root/common/blocks';
import {editor} from 'monaco-editor';
import {BlockBuilderComponentBase} from '@bb/base/ComponentBase';
import {BlockManService} from '@bb/block-man.service';
import {BlockHostService} from '@bb/block-host.service';
import {debounce} from '@root/util/debounce';
import IEditorConstructionOptions = editor.IEditorConstructionOptions;

@Component({
	selector: 'upm-bb-backend-editor',
	templateUrl: './bb-backend-editor.component.html',
	styleUrls: ['./bb-backend-editor.component.less']
})
export class BbBackendEditorComponent extends BlockBuilderComponentBase implements OnInit {

	@Input() block: IBlock;

	options: IEditorConstructionOptions = {
		folding: true,
		language: 'python',
		minimap: {enabled: false},
		readOnly: false,
		smoothScrolling: true,
		renderLineHighlight: 'none',
		autoIndent: true
	};

	private readonly validateDebounced;

	constructor(bman: BlockManService, private readonly host: BlockHostService) {
		super(bman);
		this.validateDebounced = debounce(this.checkSyntaxForErrors, 2000, {context: this, isImmediate: false});
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.validateDebounced();
	}

	async update(): Promise<any> {
		this.validateDebounced();
	}

	async checkSyntaxForErrors() {
		this.blockman.backendSyntaxValid =
			await this.host.checkBackendCodeValidity(this.block.backend);
	}

}
