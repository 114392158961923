import {Component, ElementRef, NgZone} from '@angular/core';

import {BlockManService} from '@bb/block-man.service';
import {buildHtml, RenderView} from '@root/common/renderer';
import {BlockBuilderComponentBase} from '@bb/base/ComponentBase';
import {debounce} from '@root/util/debounce';
import {IGlue, ISetting} from '@root/common/blocks';
import {getHashCode} from '@root/util/hash';
import {BlockHostService} from '@bb/block-host.service';
import {findChildWithClassName} from '@upm/common/webutils';
import {MessageBoxService} from '@upm/message-box.service';

@Component({
	selector: 'upm-bb-render',
	templateUrl: './bb-render.component.html',
	styleUrls: ['./bb-render.component.less']
})
export class BbRenderComponent extends BlockBuilderComponentBase {

	private readonly renderDebounced: any;
	private readonly _rv: RenderView;

	private _lastHashCode: number = 0;
	private _htmlHostElement: HTMLDivElement;
	private _lglue: IGlue;

	constructor(private readonly elem: ElementRef,
				private readonly zone: NgZone,
				private readonly mbox: MessageBoxService,
				private readonly host: BlockHostService,
				blockman: BlockManService) {
		super(blockman);

		this._rv = new RenderView(zone, host);

		this._rv.onError = (source, error) => {

			if (error instanceof Error) {
				let serr = error.message + "\n" + error.name;
				this.blockman.setGlobalError(source, serr);
			} else {
				let serr = error.toString().trim();
				this.blockman.setGlobalError(source, serr);
			}
		};

		this._rv.initSession = async () => {

			if (this.blockman.unsaved) {
				await this.mbox.freeze(this.host.save(), 'Saving current block...');
			}

			const sessionId = await this.mbox.freeze(this.host.startMockSession(this.block.id), 'Starting session...');
			this._rv.setSession(sessionId);

			return true;
		};

		this.renderDebounced = debounce(this.render, 600, {context: this, isImmediate: false});
	}

	private getSettings() {
		const settings = {};

		const srm = {};
		for (let setting of this.block.settings) {
			srm[setting.id] = setting;
		}

		if (this.blockman.activeExample) {
			const example = this.blockman.activeExample;
			for (let sev of example.values) {
				const setting = srm[sev.referenceId] as ISetting;
				settings[setting.name] = sev.value;
			}
		}
		return settings;
	}

	private calculateBlockAppHashCode() {

		const htmlHash = getHashCode(buildHtml(this.block.root, 'invariant'));
		const jsHash = getHashCode(this.block.js);
		const backendHash = getHashCode(this.block.backend);
		const settingsHash = getHashCode(JSON.stringify(this.getSettings()));

		const total = {'html': htmlHash, 'js': jsHash, 'settings': settingsHash, 'backend': backendHash};
		return getHashCode(JSON.stringify(total));
	}

	private async render() {

		const start = window.performance.now();

		const hash = this.calculateBlockAppHashCode();
		if (hash == this._lastHashCode) {
			return;
		}

		const glueChanged = this.host.glue !== this._lglue;
		if (glueChanged) {
			this._rv.clearSession();
		}

		this._lglue = this.host.glue;
		await this._rv.setGlue(this.host.glue);

		this._htmlHostElement.innerHTML =
			await this._rv.render(this.block, this.blockman.activeExample);

		setTimeout(async () => {
			await this._rv.runApplication(this.block, this.blockman.activeExample);
		});

		const end = window.performance.now();
		console.log(`Render Took [${end -start}ms]`)
	}

	async update() {
		await super.update();
		this.renderDebounced();
	}

	get rpcInProgress() {
		return this._rv.rpcInProgress;
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		this._rv.destroy();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.blockman.reloads.subscribe(async () => {
			this._lastHashCode = -1;
			await this.update();
		});

		this._htmlHostElement = findChildWithClassName(this.elem.nativeElement, 'render-container');
	}
}
