import {Injectable, NgZone} from '@angular/core';
import {CanActivate, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {environment} from "@root/environments/environment.prod";


const LS_USERNAME = "__upw_user";
const LS_PASS = "__upw_pass";


@Injectable({
	providedIn: 'root'
})
export class SessionService implements CanActivate {

	username: string = "";
	passtoken: string = "";

	private _signedIn: boolean = false;

	private _automaticsignInResolver = null;
	private _automaticSigningIn: Promise<void> = new Promise((resolve => {
		this._automaticsignInResolver = resolve;
	}));

	globalErrorMessage: string | null = null;

	constructor(
		private readonly router: Router,
		private readonly http: HttpClient,
		private readonly zone: NgZone,
	) {

		zone.run(async () => {
			const user = localStorage.getItem(LS_USERNAME);
			const pass = localStorage.getItem(LS_PASS);
			if (user && pass) {
				await this.signIn(user, pass);
			}
			this._automaticsignInResolver();
		})
	}

	globalError(error: any) {
		console.error(error);

		if (typeof error == 'object') {
			try {
				error = JSON.stringify(error, null, 4);
			} catch (e) {
				// OK, no good. Leave it for the console.
			}

		}

		this.globalErrorMessage = error;
	}

	async signIn(username: string, password: string): Promise<boolean> {

		this._signedIn = false;

		const result = await this.http.post(environment.backend + "account-management", {
			"action": "login",
			"userId": username,
			"passtoken": password
		}).toPromise() as any as {
			"ok": boolean,
			"error": string
			"data": {
				userId: string;
			}
		};

		if (result.ok) {
			this.username = result.data.userId;
			this.passtoken = password;
			this._signedIn = true;

			localStorage.setItem(LS_USERNAME, username);
			localStorage.setItem(LS_PASS, password);
		}

		return this.isSignedIn;
	}

	async signOut() {
		localStorage.setItem(LS_USERNAME, null);
		localStorage.setItem(LS_PASS, null);
		this._signedIn = false;
		this.username = "";
		this.passtoken = "";
		await this.router.navigate(["signin"]);
	}

	get isSignedIn(): boolean {
		return this._signedIn;
	}

	get isDeveloper(): boolean {
		if (!this.username)
			return false;

		if (location.hostname === "localhost")
			return true;

		return this.username.indexOf("gleb") === 0;
	}

	async canActivate() {
		await this._automaticSigningIn;
		if (!this.isSignedIn) {
			await this.router.navigate(['signin']);
			return false;
		}
		return this.isSignedIn;
	}
}
