import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {IBlock} from "@common/blocks";
import {BlockHostService} from "@bb/block-host.service";
import {SessionService} from "@upm/session.service";

interface IUserDescriptor {
	userId: string;
	friendlyName: string;
}

@Component({
	selector: 'upm-bb-transfer-dialog',
	templateUrl: './bb-transfer-dialog.component.html',
	styleUrls: ['./bb-transfer-dialog.component.less']
})
export class BbTransferDialogComponent implements OnInit {

	block: IBlock;
	users: IUserDescriptor[];
	targetUserId: number = null;
	loading = false;

	constructor(@Inject(MAT_DIALOG_DATA) public data: { block: IBlock },
				private readonly dialog: MatDialogRef<any, any>,
				private readonly session: SessionService,
				private readonly host: BlockHostService) {
		this.block = data.block;
	}

	async ngOnInit() {
		this.loading = true;
		this.users = await this.host.listUsers() as any;
		console.log("Session.username", this.session.username);
		this.users = this.users.filter((u) => u.userId != this.session.username);
		this.loading = false;
	}

	async transfer() {
		this.loading = true;
		await this.host.transferBlock(this.block.id, this.targetUserId);
		this.loading = false;
		this.dialog.close(true);
	}

	showName(user: IUserDescriptor) {
		if (user.friendlyName)
			return user.friendlyName;
		return user.userId;
	}
}
