import {Component, Input} from '@angular/core';
import {IBlockNode} from '@root/common/blocks';
import {BlockManService} from '@bb/block-man.service';

@Component({
	selector: 'upm-bb-layout',
	templateUrl: './bb-layout.component.html',
	styleUrls: ['./bb-layout.component.less']
})
export class BbLayoutComponent {

	@Input() node: IBlockNode;

	constructor(private readonly blockman: BlockManService) {

	}

	get highlight(){
		return this.blockman.focusNode === this.node;
	}

}
