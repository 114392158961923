import {Injectable} from '@angular/core';
import {SessionService} from '@upm/session.service';
import {HttpClient} from '@angular/common/http';
import {IBlockMetaDescriptor} from '@bb/block-host.service';
import {IBlock, IGlue} from '@root/common/blocks';
import {BlockManService} from '@bb/block-man.service';
import {MessageBoxService} from '@upm/message-box.service';
import {environment} from "@root/environments/environment.prod";

interface IStandardResponse {
	ok: boolean;
	error?: string;
	data?: any;
}

export interface IBlockMetaDescriptor {
	id: number;
	name: string;
	createdOn: string;
}

export interface IBlockConfig {
	backend: string;
	block: string;
}


@Injectable({
	providedIn: 'root'
})
export class BlockHostService {


	private managementBackend = environment.backend + 'block-management';
	private rpcBackend = environment.backend + 'block-rpc';

	glue: IGlue = null;
	private lastBackendCode: string = null;

	private mockSessionStartedForBackendReference = null;
	private mockLastBackendCode = null;
	private mockSessionPromiseResolver = null;
	private mockSessionPromise = new Promise<number>(r => {
		this.mockSessionPromiseResolver = r;
	});

	constructor(private readonly session: SessionService,
				private readonly http: HttpClient,
				private readonly blockman: BlockManService,
				private readonly mbox: MessageBoxService
	) {

		blockman.observe().subscribe(async (block: IBlock) => {

			if (block.backend != this.mockLastBackendCode) {
				this.mockLastBackendCode = block.backend;

				const previousResolver = this.mockSessionPromiseResolver;
				this.mockSessionPromise = new Promise(r => {
					this.mockSessionPromiseResolver = r;
				});

				if (previousResolver) {
					this.mockSessionPromise.then((value) => {
						previousResolver(value);
					});
				}
			}

			await this.checkBackendCodeValidity(block.backend)

		});
	}

	private async postAny(backend: string, action: string, payload: any = {}): Promise<any> {

		const signedPayload = {
			'userId': this.session.username,
			'passtoken': this.session.passtoken,
			'action': action,
			...payload
		};

		const result = this.http.post(backend, signedPayload).toPromise();

		return new Promise<any>(async r => {

			let error = null;
			try {
				const response = await result as IStandardResponse;
				if (response.ok) {
					r(response.data);
				} else {
					error = response.error;
				}
			} catch (e) {
				error = e;
			}

			if (error != null) {
				await this.session.globalError(error);
			}
		});
	}

	private async post(action: string, payload: any = {}): Promise<any> {
		return this.postAny(this.managementBackend, action, payload);
	}

	async getBlockList() {
		return await this.post('list') as IBlockMetaDescriptor[];
	}

	async createNewBlock(name: string): Promise<number> {
		const data = await this.post('create', {name: name});
		return data["blockId"] as number;
	}

	async updateBlock(blockId: number, name: string, deleted: boolean = false) {
		await this.post('update', {'blockId': blockId, 'name': name, 'deleted': deleted});
	}

	async setBlockConfiguration(block: IBlock) {

		const copy = {...block};
		delete copy['backend'];

		const clientPayload = JSON.stringify(copy);
		const backendCode = block.backend;

		await this.post('set-config', {
			'blockId': block.id,
			'clientPayload': clientPayload,
			'backendCode': backendCode
		});
	}

	async getBlockConfiguration(blockId: number): Promise<IBlockConfig> {
		return await this.post('get-config', {'blockId': blockId});
	}

	async renameBlock(blockId: number, name: string): Promise<IBlockConfig> {
		return await this.post('rename-block', {'blockId': blockId, "name": name});
	}

	async transferBlock(blockId: number, userId: number): Promise<IBlockConfig> {
		return await this.post('transfer-block', {'blockId': blockId, "targetUserId": userId});
	}

	async listUsers(): Promise<IBlockConfig> {
		return await this.post('list-users', {});
	}

	async checkBackendCodeValidity(code: string): Promise<boolean> {

		if (code != this.lastBackendCode)
			this.glue = await this.generateGlue(code);

		this.lastBackendCode = code;
		return this.glue != null;
	}

	async generateGlue(code: string): Promise<IGlue> {
		const glue = await this.post('validate-code', {'backendCode': code}) as { glue: string };

		if (glue === null) {
			return null;
		}

		return JSON.parse(glue.glue);
	}

	async startMockSession(blockId: number): Promise<number> {

		const instanceCreationResult = await this.post('instance-start', {
			'blockId': blockId,
			'isDevelopment': true,
			'settings': '{}'
		}) as { instanceId: number };

		const instanceId = instanceCreationResult.instanceId;

		const sessionCreationResult = await this.post('session-start', {
			'instanceId': instanceId,
			'isDevelopment': true,
		}) as { sessionId: number };

		return sessionCreationResult.sessionId;
	}

	async startTrackedMockSession(blockId: number) {
		const sessionId = await this.startMockSession(blockId);
		this.mockSessionPromiseResolver(sessionId);
	}

	async save() {
		await this.mbox.freeze(this.setBlockConfiguration(this.blockman.block), 'Saving changes to block config...');
		this.blockman.state.state = 'prestine';
		this.blockman.saveState();
	}

	private async startAutomaticMockSession() {

		if (!this.blockman.backendSyntaxValid) {
			await this.mbox.showErrorMessage('The backend code has syntax errors, and cannot be played');
			return;
		}

		if (this.blockman.unsaved) {

			if (await this.mbox.confirmSaveFor(this.blockman.block.name)) {
				await this.save();
			} else {
				return false;
			}
		}

		const block = this.blockman.block;
		await this.mbox.freeze(this.startTrackedMockSession(block.id), 'Starting a mock session...');
		return true;
	}

	async rpc(fn: string, params: any[]): Promise<any> {

		if (this.mockSessionStartedForBackendReference !== this.blockman.block.backend) {
			const started = await this.startAutomaticMockSession();
			if (!started) {
				throw 'Unable to make RPC call';
			}
			this.mockSessionStartedForBackendReference = this.blockman.block.backend;
		}

		const sessionId = await this.mockSessionPromise;
		return await this.sessionRPC(sessionId, fn, params);
	}

	async sessionRPC(sessionId: number, fn: string, params: any[]): Promise<any> {
		return await this.postAny(this.rpcBackend, fn, {
			'sessionId': sessionId,
			'params': params
		});
	}


}
