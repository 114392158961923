import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ALLBLOCKS, BlockHtmlType, blockTag, IBlockNode} from '@root/common/blocks';

@Component({
	selector: 'upm-bb-nest-node-editor',
	templateUrl: './bb-nest-node-editor.component.html',
	styleUrls: ['./bb-nest-node-editor.component.less']
})
export class BbNestNodeEditorComponent implements OnInit {

	types = ALLBLOCKS;

	@Input() nodestream: Observable<IBlockNode>;

	node: IBlockNode | null = null;

	ngOnInit() {
		this.nodestream.subscribe((node) => {
			this.node = node;
		});
	}

	get isInputNode() {
		return (this.node.htmlType === BlockHtmlType.INPUT) || (this.node.htmlType === BlockHtmlType.TEXTAREA);
	}

	blockDisplay(block: BlockHtmlType) {
		const tag = blockTag(block, this.node);
		return `<${tag}/>`;
	}
}
