import {Component, Input} from '@angular/core';
import {IBlock} from '@root/common/blocks';
import {editor} from 'monaco-editor';
import IEditorConstructionOptions = editor.IEditorConstructionOptions;
import {debounce} from '@root/util/debounce';

@Component({
	selector: 'upm-bb-code-editor',
	templateUrl: './bb-code-editor.component.html',
	styleUrls: ['./bb-code-editor.component.less']
})
export class BbCodeEditorComponent {

	@Input() block: IBlock;

	js = "";

	updatedCode: string;

	options: IEditorConstructionOptions = {
		folding: true,
		language: 'javascript',
		minimap: {enabled: false},
		readOnly: false,
		smoothScrolling: true,
		renderLineHighlight: 'none',
		autoIndent: true,

	};

	private dupd = debounce(this.update, 1200, {context: this, isImmediate: false});

	constructor() {

	}

	ngOnInit() {
		this.js = this.block.js;
	}

	applyChange(code: string) {
		this.updatedCode = code;
		this.dupd();
	}

	private update() {
		this.block.js = this.updatedCode;
	}
}
