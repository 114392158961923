export type Procedure = (...args: any[]) => void;

export type Options = {
	isImmediate: boolean,
	context: any | undefined
}

export function debounce<F extends Procedure>(
	func: F,
	waitMilliseconds = 50,
	options: Options = {
		isImmediate: false,
		context: undefined
	},
): F {

	let timeoutId: any | undefined;

	return function (...args: any[]) {
		const context = options.context;

		const doLater = function () {
			timeoutId = undefined;
			if (!options.isImmediate) {
				func.apply(context, args);
			}
		};

		const shouldCallNow = options.isImmediate && timeoutId === undefined;

		if (timeoutId !== undefined) {
			clearTimeout(timeoutId);
		}

		timeoutId = setTimeout(doLater, waitMilliseconds);

		if (shouldCallNow) {
			func.apply(context, args);
		}

	} as any;
}
