import {Component, ElementRef, NgZone, OnDestroy, OnInit} from '@angular/core';
import {BlockHostService} from '@bb/block-host.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {MessageBoxService} from '@upm/message-box.service';
import {RenderView} from '@root/common/renderer';
import {IBlock} from '@root/common/blocks';
import {findChildWithClassName} from '@upm/common/webutils';


@Component({
	selector: 'upm-bb-isolation-view',
	templateUrl: './bb-isolation-view.component.html',
	styleUrls: ['./bb-isolation-view.component.less']
})
export class BbIsolationViewComponent implements OnInit, OnDestroy {

	private _blockId: number = null;
	private _routeParameterSubscription: Subscription = null;
	private _rv: RenderView = null;

	constructor(
		private route: ActivatedRoute,
		private readonly elem: ElementRef,
		private readonly zone: NgZone,
		private readonly host: BlockHostService,
		private readonly mbox: MessageBoxService
	) {

	}

	async ngOnInit() {
		this._routeParameterSubscription = this.route.paramMap.subscribe(async (params) => {
			await this.zone.run(async () => {

				const blockId = parseInt(params.get('blockId'));
				const exampleIdx = parseInt(params.get('exampleIdx'));

				if (blockId) {
					const task = this.initializeViewFor(blockId, exampleIdx);
					await this.mbox.freeze(task, 'Loading...');
				}
			});
		});
	}

	async initializeViewFor(blockId: number, exampleIdx: number) {
		const configTask = this.host.getBlockConfiguration(blockId);
		const sessionIdTask = this.host.startMockSession(blockId);

		const config = await configTask;
		const block = JSON.parse(config.block) as IBlock;
		const example = block.examples[exampleIdx] || null;
		block.backend = config.backend;
		this._blockId = block.id;

		const glue = await this.host.generateGlue(block.backend);
		const sessionId = await sessionIdTask;

		this._rv = new RenderView(this.zone, this.host);

		this._rv.setSession(sessionId);
		this._rv.setGlue(glue);

		const target = findChildWithClassName(this.elem.nativeElement, 'render-container');
		target.innerHTML = await this._rv.render(block, example);

		setTimeout(async () => {
			await this._rv.runApplication(block, example);
		});
	}

	ngOnDestroy(): void {
		if (this._routeParameterSubscription) {
			this._routeParameterSubscription.unsubscribe();
		}
	}

	get rpcInProgress() {
		if (this._rv)
			return this._rv.rpcInProgress;
		return false;
	}

	get blockId(): number {
		return this._blockId;
	}
}
