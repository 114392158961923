import {Component, Input} from '@angular/core';
import {blockTag, IBlock, IBlockNode} from '@root/common/blocks';
import {BlockManService} from '@bb/block-man.service';
import {MessageBoxService} from "@upm/message-box.service";

@Component({
	selector: 'upm-bb-nest-field-editor',
	templateUrl: './bb-nest-field-editor.component.html',
	styleUrls: ['./bb-nest-field-editor.component.less']
})
export class BbNestFieldEditorComponent {

	@Input() node: IBlockNode;
	@Input() block: IBlock;

	constructor(private readonly blockman: BlockManService,
				private readonly mbox: MessageBoxService) {
	}

	get repeated() {
		return this.node.repeatAction || this.node.repeatBinding;
	}

	get isCut() {
		return this.node === this.blockman.cutNode;
	}

	get canPaste() {
		return this.blockman.canPasteNode(this.node);
	}

	get tag() {
		const type = blockTag(this.node.htmlType, this.node);
		let s = `<${type} `;

		let classDef = "";
		if (this.node.className.length > 0) {
			let classes = this.node.className;
			const mlen = 25;
			if (classes.length > mlen) {
				classes = classes.substr(0, mlen - 3) + "...";
			}
			classDef += `class='${classes}'`;
		}


		s += classDef;
		s += ' />';

		return s;
	}

	addChild() {
		this.blockman.addChildBlock(this.node);
	}

	async remove(event: MouseEvent) {
		if (event.shiftKey || await this.mbox.confirmDeleteFor("node")) {
			this.blockman.removeBlock(this.node);
		}
	}

	wrap(event: MouseEvent) {
		const unwrap = !event.shiftKey;
		if (unwrap) {
			this.blockman.wrapBlock(this.node);
		} else {
			this.blockman.unwrapBlock(this.node);
		}
	}


	promote(event: MouseEvent) {
		const fully = event.shiftKey;
		this.blockman.promote(false, fully, this.node);
	}

	demote(event: MouseEvent) {
		const fully = event.shiftKey;
		this.blockman.promote(true, fully, this.node);
	}

	cut($event: MouseEvent) {
		if (this.blockman.cutNode != this.node) {
			this.blockman.cutNode = this.node;
		} else {
			this.blockman.cutNode = null;
		}
	}

	paste() {
		this.blockman.pasteNode(this.node);
	}

	duplicate() {
		this.blockman.duplicateNode(this.node);
	}
}
