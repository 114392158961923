import {Component} from '@angular/core';
import {ControlType, IBlock, ISetting} from '@root/common/blocks';
import {BlockManService} from '../block-man.service';
import {makeReandomIdentifier} from '@root/common/dom';
import {MessageBoxService} from '@upm/message-box.service';

@Component({
	selector: 'upm-bb-settings-editor',
	templateUrl: './bb-settings-editor.component.html',
	styleUrls: ['./bb-settings-editor.component.less']
})
export class BbSettingsEditorComponent {

	block: IBlock | null = null;
	controlTypes: ControlType[] = [ControlType.Real, ControlType.Color, ControlType.Thickness, ControlType.String];

	constructor(private readonly blockman: BlockManService, private readonly messageBox: MessageBoxService) {
		this.blockman.observe().subscribe((block: IBlock) => {
			this.block = block;
			this.update();
		});
	}

	update() {

	}

	controlTypeName(ct: ControlType) {
		switch (ct) {
			case ControlType.String:
				return 'String';
			case ControlType.Real:
				return 'Number';
			case ControlType.Color:
				return 'Color';
			case ControlType.Thickness:
				return 'Thickness';
		}

		return 'Unknown';
	}

	addNewSetting() {
		if (this.block) {
			this.block.settings.push({
				id: makeReandomIdentifier(),
				name: 'setting_' + (this.block.settings.length + 1).toString(),
				description: '',
				controlType: ControlType.Real,
				controlTypeParameters: {'min': 0, 'max': 10},
				defaultValueInitializor: "",
				required: false
			});
		}
	}

	async deleteSetting(setting: ISetting) {
		if (this.block) {
			if (await this.messageBox.confirmDeleteFor(setting.name)) {
				this.blockman.removeSetting(setting);
			}
		}
	}

}
