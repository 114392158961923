import {Component, DoCheck, OnInit} from '@angular/core';
import {IBlock} from '@root/common/blocks';
import {BlockManService} from '../block-man.service';

@Component({
	selector: 'upm-bb-home',
	templateUrl: './bb-home.component.html',
	styleUrls: ['./bb-home.component.less']
})
export class BbHomeComponent implements OnInit {

	private block: IBlock;

	constructor(private readonly blockman: BlockManService) {

	}

	ngOnInit() {

	}


	get haveOpenedBlock() {
		return this.blockman.block !== null;
	}


}
