import {Component} from '@angular/core';
import {IBlock} from '../../../../common/blocks';
import {BlockManService, IValidationResult} from '../block-man.service';

@Component({
	selector: 'upm-bb-structure-editor',
	templateUrl: './bb-structure-editor.component.html',
	styleUrls: ['./bb-structure-editor.component.less']
})
export class BbStructureEditorComponent {
	block: IBlock | null = null;

	private validationResult: IValidationResult;

	constructor(private readonly blockman: BlockManService) {
		this.blockman.observe().subscribe((block: IBlock) => {
			this.block = block;
			this.update();
		});
	}

	private update() {

	}

	get examplesValid() {
		this.validationResult = this.blockman.validateExamples();
		return this.validationResult.valid;
	}

	get examplesErrorText() {
		if (this.validationResult) {
			return this.validationResult.error;
		}
	}

}
