import {Component} from '@angular/core';
import {SessionService} from "@upm/session.service";
import {Router} from "@angular/router";
import {MessageBoxService} from "@upm/message-box.service";

@Component({
	selector: 'upm-signin',
	templateUrl: './signin.component.html',
	styleUrls: ['./signin.component.less']
})
export class SigninComponent {

	username: string = "";
	password: string = "";

	constructor(private readonly session: SessionService,
				private readonly mbox: MessageBoxService,
				private readonly router: Router) {
	}

	async signIn() {
		const ok = await this.session.signIn(this.username, this.password);
		if (!ok) {
			await this.mbox.showErrorMessage("Unable to sign in with provided credentials");
		} else {
			await this.router.navigate([""]);
		}
	}
}
