import {ChangeDetectorRef, Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IBlockNode} from '@root/common/blocks';
import {initDomAdapter} from '@angular/platform-browser/src/browser';
import {initTransferState} from '@angular/platform-browser/src/browser/transfer_state';

interface IKeyValuePair {
	key: string;
	value: string;
}

@Component({
	selector: 'upm-bb-nest-node-attrs-editor',
	templateUrl: './bb-nest-node-attrs-editor.component.html',
	styleUrls: ['./bb-nest-node-attrs-editor.component.less']
})
export class BbNestNodeAttrsEditorComponent implements OnInit, DoCheck, OnChanges {

	@Input() node: IBlockNode;

	table: IKeyValuePair[] = [];

	constructor() {

	}

	init() {

		if (!this.node.attrs) {
			this.node.attrs = {};
		}

		this.table.length = 0;

		for (let key in this.node.attrs) {
			this.table.push({key: key, value: this.node.attrs[key]});
		}

	}

	ngOnInit() {

	}

	addAttribute() {
		this.table.push({key: 'attr', value: 'value'});
	}

	ngDoCheck(): void {
		const attrs = this.node.attrs = {};
		for (let attr of this.table) {
			attrs[attr.key] = attr.value;
		}
	}

	removeAttribute(attr: IKeyValuePair) {
		const idx = this.table.indexOf(attr);
		if (idx !== -1) {
			this.table.splice(idx, 1);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.init();
	}
}
