import {Component} from '@angular/core';
import {BlockManService} from "@bb/block-man.service";
import {BlockHostService} from "@bb/block-host.service";

@Component({
	selector: 'upm-bb-debugger',
	templateUrl: './bb-debugger.component.html',
	styleUrls: ['./bb-debugger.component.less']
})
export class BbDebuggerComponent {

	blockJson: string = null;
	blockJsonInitString: string = "";

	constructor(
		private readonly blockman: BlockManService,
		private readonly host: BlockHostService) {
	}


	generateJson() {
		console.log("this.blockamn.blokc", this.blockman.block);
		const json = JSON.stringify(this.blockman.block, null, 4);
		this.blockJson = json;
	}

	copy() {
		(navigator as any).clipboard.writeText(this.blockJson);
	}

	async setObjectProperties() {

		const src = JSON.parse(this.blockJsonInitString);
		for (let key in src) {
			if (key !== "id") {
				this.blockman.block[key] = src[key];
			}
		}

		this.blockJsonInitString = null;
		this.blockman.autosaveChange(true);
		await this.host.save();

		setTimeout(() => {
			window.location.href = "/";
		}, 100);
	}

	get isValidBlockJson() {

		if (!this.blockJsonInitString) {
			console.log("F1");
			return false;
		}

		if (this.blockJsonInitString.length === 0) {
			console.log("F2");
			return false;
		}

		try {
			JSON.parse(this.blockJsonInitString);
		} catch (e) {
			console.log("F3");
			return false;
		}

		return true;
	}
}
