import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {IBlock, IBlockNode} from '../../../../common/blocks';

import {MatTreeNestedDataSource} from '@angular/material';
import {NestedTreeControl} from '@angular/cdk/tree';
import {BehaviorSubject, Subject} from 'rxjs';
import {BlockManService} from '../block-man.service';

@Component({
	selector: 'upm-bb-nest-editor',
	templateUrl: './bb-nest-editor.component.html',
	styleUrls: ['./bb-nest-editor.component.less']
})
export class BbNestEditorComponent {

	readonly treeControl = new NestedTreeControl<IBlockNode>(node => node.children);
	readonly dataSource = new MatTreeNestedDataSource<IBlockNode>();
	readonly nodestream = new BehaviorSubject<IBlockNode | null>(null);

	block: IBlock | null = null;
	selectedNode: IBlockNode | null = null;
	private initialLoad = true;

	constructor(private readonly blockman: BlockManService) {
		this.blockman.observe().subscribe((block: IBlock) => {
			this.block = block;
			this.update();
		});
	}

	private update() {

		const root = this.block.root;

		const data = [root];
		this.dataSource.data = null;
		this.dataSource.data = data;
		this.treeControl.dataNodes = data;


		if (this.initialLoad) {
			this.treeControl.expand(root);
			for (let child of root.children) {
				this.treeControl.expand(child);
			}
		}

		let reset = true;
		if (this.selectedNode != null) {
			if (this.blockman.isAlive(this.selectedNode)) {
				reset = false;
			}
		}

		if (reset) {
			this.select(root);
		}

		this.initialLoad = false;
	}

	hasChild(n: number, node: IBlockNode) {
		return node.children.length > 0;
	}

	select(node: IBlockNode) {
		this.nodestream.next(node);
		this.selectedNode = node;
		this.blockman.focusNode = node;
		this.treeControl.expand(node);
	}

	isCut(node: any) {
		return this.blockman.cutNode === node;
	}
}
