import {OnDestroy, OnInit} from '@angular/core';

import {IBlock} from '@root/common/blocks';
import {BlockManService} from '@bb/block-man.service';
import {Subscription} from 'rxjs';


export class BlockBuilderComponentBase implements OnDestroy, OnInit {

	block: IBlock;

	protected destroyed: boolean;
	private sub: Subscription;

	constructor(
		protected readonly blockman: BlockManService) {
	}

	async update() {

	}

	ngOnDestroy(): void {
		this.destroyed = true;
		this.sub.unsubscribe();
	}

	ngOnInit(): void {
		this.sub = this.blockman.observe().subscribe(async (block: IBlock) => {
			if (block) {
				this.block = block;
				await this.update();
			}
		});
	}

}
