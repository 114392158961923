import {ApplicationRef, EventEmitter, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ConfirmDeleteDialogComponent} from './sections/common/confirm-delete-dialog/confirm-delete-dialog.component';
import {ConfirmDiscardDialogComponent} from '@upm/sections/common/confirm-discard-dialog/confirm-discard-dialog.component';
import {ErrorDialogComponent} from '@upm/sections/common/error-dialog/error-dialog.component';
import {ConfirmSaveDialogComponent} from '@upm/sections/common/confirm-save-dialog/confirm-save-dialog.component';


export interface IFrozenScreen {
	text: string | null;
}

@Injectable({
	providedIn: 'root'
})
export class MessageBoxService {

	private frozenScreens: IFrozenScreen[] = [];
	changes: EventEmitter<void> = new EventEmitter<void>();

	constructor(private readonly dialog: MatDialog) {

	}

	async confirmDeleteFor(name: string): Promise<boolean> {

		const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
			width: '450px',
			panelClass: 'default-dialog-container',
			data: {name: name}
		});

		return await dialogRef.afterClosed().toPromise();
	}

	async confirmDiscardFor(name: string): Promise<boolean> {

		const dialogRef = this.dialog.open(ConfirmDiscardDialogComponent, {
			width: '450px',
			panelClass: 'default-dialog-container',
			data: {name: name}
		});

		return await dialogRef.afterClosed().toPromise();
	}

	async confirmSaveFor(name: string): Promise<boolean> {

		const dialogRef = this.dialog.open(ConfirmSaveDialogComponent, {
			width: '450px',
			panelClass: 'default-dialog-container',
			data: {name: name}
		});

		return await dialogRef.afterClosed().toPromise();
	}

	async showErrorMessage(error: string) {
		const dialogRef = this.dialog.open(ErrorDialogComponent, {
			width: '450px',
			panelClass: 'default-dialog-container',
			data: {error: error}
		});

		return await dialogRef.afterClosed().toPromise();
	}

	private enableFrostScreen(frostText: string) {
		this.frozenScreens.push({
			text: frostText
		});
		this.changes.emit();
	}

	private disableFrostScreen() {
		this.frozenScreens.pop();
		this.changes.emit();
	}

	async freeze<T>(promise: Promise<T>, frostText: string = 'Loading, please wait'): Promise<T> {

		this.enableFrostScreen(frostText);

		try {
			await promise;
		} finally {
			this.disableFrostScreen();
		}

		return promise;
	}

	get isFrozen() {
		return this.frozenScreens.length > 0;
	}

	get topFrozenScreen() {
		return this.frozenScreens[this.frozenScreens.length - 1];
	}


}
