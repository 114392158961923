import {Component, Input, OnInit} from '@angular/core';

import {IBlock} from '@root/common/blocks';
import {editor} from 'monaco-editor';
import IEditorConstructionOptions = editor.IEditorConstructionOptions;
import {debounce} from "@root/util/debounce";

@Component({
	selector: 'upm-bb-style-editor',
	templateUrl: './bb-style-editor.component.html',
	styleUrls: ['./bb-style-editor.component.less']
})
export class BbStyleEditorComponent implements OnInit {

	@Input() block: IBlock;

	stylesheet = "";

	updatedCode: string;

	options: IEditorConstructionOptions = {
		folding: true,
		language: 'less',
		minimap: {enabled: false},
		readOnly: false,
		smoothScrolling: true,
		renderLineHighlight: 'none',
		autoIndent: true
	};

	private dupd = debounce(this.update, 300, {context: this, isImmediate: false});

	constructor() {
	}

	ngOnInit() {
		this.stylesheet = this.block.stylesheet;
	}

	applyChange(code: string) {
		this.updatedCode = code;
		this.dupd();
	}

	private update() {
		this.block.stylesheet = this.updatedCode;
	}


}
